import React, { useEffect, useState, useMemo } from 'react';
import { m, AnimatePresence, AnimationProps } from 'framer-motion';
import { NoodleProductTypes } from '@typings/graphql-models';

import Button from 'components/Buttons';
import * as fbq from '@providers/FacebookPixel';
import SvgNoodle from 'components/Icons/Noodle';
import VideoCamera from 'components/Icons/VideoCamera';
import BookmarkSimple from 'components/Icons/BookmarkSimple';
import MessageTip from '@components/MessageTip';
import ShoppingCart from '@providers/ShoppingCart';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import { useRouter } from 'next/router';
import { getUTMParams } from '@helpers/analytics';
import sortBy from '@helpers/sortBy';
import { getCreatorProducts, isProductBuy } from '@tsClient';
import useNoodleApi from '@hooks/useNoodleApi';

import { useUser } from '@hooks';
import * as format from '@format';
import Image from '@components/Image';
import { isInIframe } from '@helpers/helper';
import { SELF_ORIGIN } from '@configuration/client';
import { type Icon as IconType } from '@phosphor-icons/react';
import s from './Upsell.module.scss';

const creatorUpSellBySlug = {
  'danny-maude': {
    componentProps: {
      buttonText: 'Subscribe for $17.99/mo',
      title: '65+ Practice Plans!',
    },
    slug: 'range-notes-subscription',
  },
  'jordan-lawley': {
    componentProps: {
      buttonText: 'Subscribe for $9.99/mo',
      title: '100+ Transformations',
    },
    slug: 'transformations-subscription',
  },
  'mike-dolce': {
    componentProps: {
      buttonText: 'Subscribe for $9.99/mo',
    },
  },
};

type UpsellProps = {
  type: NoodleProductTypes;
  creatorSlug: string;
  isMinified?: boolean;
};

const Upsell = ({ type, creatorSlug, isMinified }: UpsellProps): JSX.Element => {
  const transition: AnimationProps['transition'] = { duration: 2, repeat: Infinity, repeatType: 'reverse', type: 'spring' };
  const cardClass = (isMinified && s.cardChatMinified)
    || (type === NoodleProductTypes.Lite && s.cardChat)
    || (type === NoodleProductTypes.Subscription && s.cardPlans)
    || s.baseCard;
  const slug = creatorSlug as 'danny-maude' | 'jordan-lawley' | undefined;
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const router = useRouter();
  const [user] = useUser();

  const upSellData = slug ? creatorUpSellBySlug[slug]?.componentProps : undefined;

  const { data: creator, getData: getCreatorInfo } = useNoodleApi(getCreatorProducts);
  const { getData: isProductBuyFn } = useNoodleApi(isProductBuy);
  const creatorImage = creator?.artist?.[0]?.person?.image?.url;
  const creatorName = creator?.artist?.[0]?.name;

  const creatorHandbooks = creator?.products?.filter(i =>
    i.productTypes.find(({ noodleProductType }) => noodleProductType === NoodleProductTypes.Handbook),
  );

  const handbooksAssets = [
    {
      count: creatorHandbooks?.length,
      type: 'lessons',
    },
    { count: creatorHandbooks?.reduce((sum, a) => sum + a.handbookSteps.length, 0), type: 'steps' },
  ];

  const productCover = creator?.products?.find(({ productTypes: PT }) =>
    PT?.some(({ noodleProductType }) => noodleProductType === NoodleProductTypes.Subscription),
  )?.image?.url;

  const product = useMemo(() => {
    const liteProduct = creator?.products?.find((p) =>
      p.isActive
      && p.canUpsell
      && p.productTypes.some(({ noodleProductType }) => noodleProductType === NoodleProductTypes.Lite),
      // @todo - should probably also by p.prices.some(p => p.isActive)
    );
    const plansProduct = creator?.products?.find((p) =>
      p.isActive
      && p.canUpsell
      && p.productTypes?.some(({ noodleProductType }) => noodleProductType === NoodleProductTypes.Subscription),
      // @todo - should probably also by p.prices.some(p => p.isActive)
    );
    if (type === NoodleProductTypes.Subscription) {
      return plansProduct;
    }
    return liteProduct;
  }, [creator, type]);

  const price = sortBy(product?.prices?.filter(pr => pr.isActive) || [], 'price', true)?.[0];

  useEffect(() => {
    if (creatorSlug) {
      getCreatorInfo({ creatorSlug });
    }
  }, [creatorSlug, getCreatorInfo]);

  useEffect(() => {
    const checkProduct = async (): Promise<void> => {
      if (!price || !product) {
        setIsShown(false);
      } else if (!user?.id) {
        setIsShown(true);
      } else {
        const response = await isProductBuyFn({ id: product.id });
        if (response.data) {
          setIsShown(!response.data.status);
        } else {
          setIsShown(false);
        }
      }
    };

    checkProduct();
  }, [price, product, user, isProductBuyFn]);

  if (!product || (!upSellData && type === NoodleProductTypes.Subscription)) {
    return <></>;
  }

  const handlePurchaseProduct = async (): Promise<void> => {
    fbq.track('Clicks Chat Button', {
      creatorSlug,
      productSlug: product?.slug,
    });
    if (!isInIframe()) {
      setIsAddingToCart(true);
      await ShoppingCart.addProduct({ price: { ...price, product } });
      await router.push(
        getUrl(IDENTIFIERS.CART, {
          creatorSlug,
          productSlug: product?.slug,
          ...getUTMParams(router.query),
        }),
      );
    } else if (product?.prices?.find((p) => p.price === 0)) {
      await router.push(getUrl(IDENTIFIERS.PRODUCT_PURCHASE, { creatorSlug, priceId: price.id, productSlug: product?.slug }));
    } else {
      window.open(`${SELF_ORIGIN}${getUrl(IDENTIFIERS.PRODUCT_PURCHASE, { creatorSlug, priceId: price.id, productSlug: product?.slug })}`);
    }
  };

  if (!isShown) {
    return <></>;
  }

  return (
    <AnimatePresence>
      <MessageTip dontShowDays={30} buttonClassName={s.tipButton} containerClassName={s.tip} messageTipKey={`${creatorSlug}__upsell__${type}`}>
        <m.button
          disabled={!isMinified}
          className={cardClass}
          exit={{ opacity: 0, scale: 0.96 }}
          transition={{ duration: 0.1, ease: 'linear' }}
          onClick={handlePurchaseProduct}
        >
          {!isMinified && type !== NoodleProductTypes.Subscription && (
            <div className={s.heading}>
              {type === NoodleProductTypes.Lite && (
                <div>
                  <SvgNoodle width={16} fill="var(--color-gray-0)"/>
                  <span className="body-md-bold">Chat with {creatorName}</span>
                </div>
              )}
            </div>
          )}
          {type === NoodleProductTypes.Lite && (
            <div className={s.animation} style={{ transform: isMinified ? 'scale(0.5)' : 'scale(1)' }}>
              <m.div className={s.circle} animate={{ scale: [0.6, 0.8] }} transition={transition} />
              <m.div className={s.circle} animate={{ scale: [0.4, 0.7] }} transition={transition} />
              <m.div className={s.circle} animate={{ scale: [0.3, 0.4] }} transition={transition} />
              <m.img src={creatorImage} alt="" className={s.creator} animate={{ scale: [1, 0.8] }} transition={transition} />
            </div>
          )}
          {isMinified && product && (
            <div className={s.minifiedLabels}>
              <p className="body-md-bold">Chat 1:1 with {creatorName}</p>
              {price && price.price !== 0 && <p className="caption">{`${format.price.recurring(price)}`}</p>}
            </div>
          )}
          {type === NoodleProductTypes.Subscription && <Image alt="" image={productCover} className={s.coverImage} />}
          <div className={s.subscriptionContent} style={{ display: type === NoodleProductTypes.Subscription ? 'flex' : 'none'}}>
            <div />
            {type === NoodleProductTypes.Subscription && (
              <div>
                <div className={s.icons}>
                  {handbooksAssets.map((i: { type: string; count?: number }) => {
                    const backgroundColor = (i.type === 'audio' && 'var(--color-success)')
                      || (i.type === 'steps' && 'var(--color-warning)')
                      || (i.type === 'lessons' && 'var(--color-primary)')
                      || 'var(--color-noodle)';

                    const Icon: IconType | undefined = (i.type === 'steps' && VideoCamera) || (i.type === 'lessons' && BookmarkSimple) || undefined;

                    return (
                      <div key={i.type}>
                        {Icon && <div className={s.icon} style={{ backgroundColor }}>
                          <Icon size={16} color="var(--color-gray-0)" weight="fill" />
                        </div>}
                        <p className="body-sm">
                          <strong>{i.count}</strong> {i.type}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {!isMinified && (
              <Button isShimmering={isAddingToCart} isSecondary isFullWidth isFlat onClick={handlePurchaseProduct}>
                {!!price && (
                  <>
                    {`${price?.priceTitle || 'Subscribe for'}`}
                    {price.price !== 0 && ` ${format.price.recurring(price)}`}
                  </>
                )}
                {!price && <>{upSellData?.buttonText}</>}
              </Button>
            )}
          </div>
        </m.button>
      </MessageTip>
    </AnimatePresence>
  );
};

export default Upsell;
