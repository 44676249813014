// @todo - this could use some robustness fixing, just making it work in tsc without changing logic for now.
enum SortTypes {
  date = 'date',
  int = 'int',
  string = 'string',
}

type UnknownObject = Record<string, unknown>;
type GetFieldValueOpts = { sortAsInt?: boolean, sortAsDate?: boolean };

function getFieldValue(obj: unknown, fieldName: string, { sortAsInt, sortAsDate }: GetFieldValueOpts = {}): string | number {
  const pathParts = fieldName.split('.');
  let lastValue: any = obj || {}; // eslint-disable-line @typescript-eslint/no-explicit-any
  pathParts.forEach(part => {
    lastValue = (lastValue || {})[part];
  });
  if (sortAsDate) {
    return new Date(lastValue as unknown as string | number || 0).getTime();
  }
  if (sortAsInt) {
    return +lastValue || 0;
  }
  return JSON.stringify(lastValue || '').toLowerCase();
}

function compare(a: number | string, b: number | string, isSortedAsc: boolean, sortAsInt: boolean): number {
  if (!sortAsInt) {
    let order = a.toString().localeCompare(b.toString(), undefined, {
      sensitivity: 'base',
    });
    if (!isSortedAsc) {
      // eslint-disable-next-line no-nested-ternary
      order = order > 0 ? -1 : (order < 0 ? 1 : 0);
    }

    return order;
  }
  return (isSortedAsc && a > b) || (!isSortedAsc && a < b) ? 1 : -1;
}

const sortBy = <T extends Array<unknown>>(
  items: T | undefined,
  field: string,
  isSortedAsc: boolean,
  type?: SortTypes | null,
  secondaryField: string | null = null,
  secondaryDirection: string | null = null,
): Array<T[0]> => {
  if (!items || items?.length === 0) return [];
  let sortAsInt: boolean;
  let sortAsString: boolean;
  let sortSecondaryAsInt: boolean;
  let sortAsDate: boolean;
  let useSecondaryDirection: boolean;
  switch (type) {
  case SortTypes.date:
    sortAsDate = true;
    break;
  case SortTypes.int:
    sortAsInt = true;
    break;
  case SortTypes.string:
    sortAsString = true; // eslint-disable-line @typescript-eslint/no-unused-vars
    break;
  default: {
    sortAsInt = !Number.isNaN(
      (items[0] as UnknownObject)[field])
      && !Number.isNaN((items[items.length - 1] as UnknownObject)[field])
      && !Array.isArray((items[0] as UnknownObject)[field],
      );
    sortSecondaryAsInt = Boolean(
      secondaryField
      && !Number.isNaN((items[0] as UnknownObject)[secondaryField])
      && !Number.isNaN((items[items.length - 1] as UnknownObject)[secondaryField]),
    );
    useSecondaryDirection = Boolean(secondaryDirection || isSortedAsc);
    sortAsDate = Boolean(
      !Number.isNaN(new Date((items[0] as UnknownObject)[field] as number).getDate())
      && !Number.isNaN(new Date((items[items.length - 1] as UnknownObject)[field] as number).getDate()),
    );
  }
  }

  if (!field) {
    return items;
  }

  return [...items].sort((a, b) => {
    const fieldA = getFieldValue(a, field, { sortAsDate, sortAsInt });
    const fieldB = getFieldValue(b, field, { sortAsDate, sortAsInt });
    if (secondaryField && fieldA === fieldB) {
      const secondaryFieldA = getFieldValue(a, secondaryField, { sortAsInt });
      const secondaryFieldB = getFieldValue(b, secondaryField, { sortAsInt });
      return compare(secondaryFieldA, secondaryFieldB, useSecondaryDirection, sortSecondaryAsInt);
    }
    return compare(fieldA, fieldB, isSortedAsc, sortAsInt || sortAsDate);
  });
};

export default sortBy;
