import React, { Fragment, FC } from 'react';
import Button from 'components/Buttons';
import File from 'components/Icons/File';
import LocalCurrencyConversion from '@components/LocalCurrencyConversion';
import classNames from 'classnames';
import * as format from '@format';
import * as ApiModels from '@typings/api-models';
import classes from './PurchaseButton.module.scss';

interface Props {
  hasActivePrice: boolean;
  firstActivePrice: { price: number; currency: string } | null;
  isFetching: boolean;
  onClick: () => void;
  hasSubscription: boolean;
  subscriptionPrice: Pick<ApiModels.Price, 'price' | 'currency' | 'recurringInterval'> | null;
  isFetchingSubscription: boolean;
  isBroadcast: boolean;
  labelSubscription: string;
  onSubscriptionClick: () => void;
  freePDFLink?: string;
  trialPeriodDays: number;
}

const PurchaseButton: FC<Props> = ({
  hasActivePrice,
  firstActivePrice,
  isFetching,
  onClick,
  hasSubscription,
  subscriptionPrice,
  isFetchingSubscription,
  isBroadcast,
  labelSubscription,
  onSubscriptionClick,
  freePDFLink,
  trialPeriodDays,
}) => (
  <Fragment>
    <div style={{ flex: 1 }}>
      {hasSubscription && subscriptionPrice
        ? (
          <Fragment>
            <div
              style={{ display: 'flex', flexWrap: 'wrap', gap: 16, width: '100%' }}
            >
              {!isBroadcast && hasActivePrice && (
                <div className={classNames(classes['purchase-button-wrapper'], classes['purchase-button-wrapper__buy-button'])}>
                  <Button
                    isThird
                    isShimmering={isFetching}
                    title="Primary Large"
                    aria-label="Primary Large"
                    onClick={onClick}
                    className={classes.buyButton}
                  >
                    {firstActivePrice && firstActivePrice.price > 0
                      ? (
                        <Fragment>
                          Buy for{' '}
                          <strong>
                            {Intl.NumberFormat('en-US', {
                              currency: firstActivePrice.currency,
                              style: 'currency',
                            }).format(firstActivePrice.price)}
                          </strong>
                        </Fragment>
                      )
                      : (
                        'Get for free'
                      )}
                  </Button>
                  {firstActivePrice && firstActivePrice.price > 0 && (
                    <LocalCurrencyConversion price={firstActivePrice.price} currency={firstActivePrice.currency}/>
                  )}
                </div>
              )}
              <div className={classNames(classes['purchase-button-wrapper'], classes['purchase-button-wrapper__subscription-buy-button'])}>
                <Button
                  isSecondary
                  isShimmering={isFetchingSubscription}
                  title="Primary Large"
                  aria-label="Primary Large"
                  onClick={onSubscriptionClick}
                  className={classes.subscribeButton}
                >
                  <span
                    style={{
                      fontSize: 10,
                      opacity: 0.8,
                      textTransform: 'uppercase',
                    }}
                  >
                    {labelSubscription}
                  </span>
                  <br />
                  for{' '}
                  {subscriptionPrice && (
                    <strong>
                      {format.price.recurring(subscriptionPrice)}
                    </strong>
                  )}
                </Button>
                {(trialPeriodDays || 0) > 0 && <span className={classNames(classes['trial-period-text'], 'caption')}>{`${trialPeriodDays} day free trial!`}</span>}
                <LocalCurrencyConversion
                  price={subscriptionPrice.price}
                  currency={subscriptionPrice.currency}
                  interval={subscriptionPrice.recurringInterval}
                />
              </div>
            </div>
          </Fragment>
        )
        : (
          <div className={classNames(classes['purchase-button-wrapper'])}>
            <Button
              isSecondary
              isLarge
              isShimmering={isFetchingSubscription}
              title="Primary Large"
              aria-label="Primary Large"
              onClick={onClick}
              className={classes.noSubscriptionBuyButton}
            >
              {firstActivePrice && firstActivePrice.price > 0
                ? (
                  <Fragment>
                    Buy for{' '}
                    <strong>
                      {Intl.NumberFormat('en-US', {
                        currency: firstActivePrice.currency,
                        style: 'currency',
                      }).format(firstActivePrice.price)}
                    </strong>
                  </Fragment>
                )
                : (
                  'Get for free'
                )}
            </Button>
            {firstActivePrice && firstActivePrice.price > 0 && (
              <LocalCurrencyConversion price={firstActivePrice.price} currency={firstActivePrice.currency} />
            )}
          </div>
        )}
      {freePDFLink && (
        <p style={{ textAlign: 'center' }}>
          <a href={freePDFLink} style={{ display: 'inline-flex', gap: 2 }}>
            <File />Download a free PDF
          </a>
        </p>
      )}
    </div>
  </Fragment>
);

export default PurchaseButton;
