import React, { ReactElement } from 'react';
import BuyProductButton from '@components/BuyProductButton';
import Link from '@components/CustomLink';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import { getProductBySlug } from '@tsClient';
import Lock from '@components/Icons/Lock';
import classNames from 'classnames';
import { useUser } from '@hooks';
import s from './BroadcastLock.module.scss';

type Props = {
  hasFreeAccess?: boolean;
  creatorSlug: string;
  upgradeTiers: string[];
  product?: Awaited<ReturnType<typeof getProductBySlug>>;
  commentId?: string;
  getPriceFromCommentPermissions?: boolean;
  isCommunityPost?: boolean;
};

const BroadcastLock = ({
  creatorSlug,
  hasFreeAccess,
  product,
  upgradeTiers,
  commentId,
  getPriceFromCommentPermissions,
  isCommunityPost,
}: Props): ReactElement => {
  const [user] = useUser();
  return (
    <section className={s.buyBroadcastForm}>
      <div className={s.titleGroup}>
        <h1 className={classNames(s.unlockTitle, 'heading-md')}>
          Unlock this
          <br />
          <span>{isCommunityPost ? 'Post' : 'Broadcast'}</span>
        </h1>
        <Lock size={24} weight="fill" color={'var(--color-primary)'} />
      </div>
      {product && (
        <div className={s.buyButton}>
          <BuyProductButton
            getPriceFromCommentPermissions={getPriceFromCommentPermissions}
            product={product}
            redirect={false}
            isFreeMembership={hasFreeAccess}
            upgradeTiers={upgradeTiers}
          />
        </div>
      )}
      {!user && (
        <p className={classNames(s.loginText, 'body-sm')}>
          <small>
            Already a member?{' '}
            <Link
              to={getUrl(IDENTIFIERS.LOGIN, {
                creatorSlug,
                returnTo:
                  product?.slug && commentId
                    ? getUrl(IDENTIFIERS.PRODUCT_COMMENT_THREAD, {
                      commentId,
                      creatorSlug,
                      productSlug: product?.slug,
                    })
                    : undefined,
              })}
            >
              <strong>Login instead</strong>
            </Link>
            .
          </small>
        </p>
      )}
    </section>
  );
};

export default BroadcastLock;
