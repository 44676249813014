import ArrowElbowDownRight from 'components/Icons/ArrowElbowDownRight';
import CustomLink from '@components/CustomLink';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import React, { useEffect, useState } from 'react';

import InputComposer from '@components/InputComposer';
import { Descendant } from 'slate';
import { mixpanelTrack } from '@providers/Mixpanel';
import { MessageType } from '@typings/api-models';
import { postNewMessage } from '@tsClient';
import useNoodleApi from '@hooks/useNoodleApi';
import { nanoid } from 'nanoid';
import { JobType, useJobContext } from '@providers/Jobs';
import ProgressIndicator from '@components/ProgressIndicator';
import { JobStatus } from '@providers/Jobs/Context';

import s from './ThreadComment.module.scss';

type PropsType = {
  comments?: {
    id: string;
    isDeleted?: boolean | null;
    parent?: {
      parent?: {
        id: string;
      };
    };
  }[];
  canReply: boolean;
  commentId: string;
  isThreadDetail?: boolean;
  isReadOnly?: boolean;
  creatorSlug: string;
  productSlug: string;
  replyInline?: boolean;
  isPostingMessage?: boolean;
  onPostNewMessage?: (comment: Awaited<ReturnType<typeof postNewMessage>>) => void;
  person?: {
    id: string;
    creator?: {
      slug: string;
    } | null;
  };
  sendAsCreator: boolean;
  isWorkflowComment?: boolean;
};

const ThreadComment: React.FC<PropsType> = ({
  comments,
  commentId,
  creatorSlug,
  isThreadDetail,
  canReply,
  children,
  isReadOnly,
  productSlug,
  replyInline = false,
  onPostNewMessage,
  person,
  sendAsCreator,
  isWorkflowComment,
}) => {
  const { fetchingState, getData: postCommentFn } = useNoodleApi(postNewMessage, {
    healthMonitor: { name: 'send-message' },
  });
  const [jobCorrelationId, setJobCorrelationId] = useState(nanoid());
  const { jobs, addJob } = useJobContext();
  const uploadJobs = jobs.filter(j => j.correlationId === jobCorrelationId);
  const [isThreadOpen, setIsThreadOpen] = useState(false);
  const productCommentThreadUrl = getUrl(IDENTIFIERS.PRODUCT_COMMENT_THREAD, { commentId, creatorSlug, productSlug });
  const WrapperComponent: React.FC = ({ children: wrapperChildren }) => (
    <CustomLink className={s['comment__start-btn']} to={productCommentThreadUrl}>
      {wrapperChildren}
    </CustomLink>
  );

  useEffect(() => {
    if (replyInline) {
      setIsThreadOpen(true);
    }
  }, [replyInline]);

  const handleSend = async ({
    text, medias = [], handbooks = [],
  }: { text?: { children: Descendant[] }[]; medias?: { id: string }[]; handbooks?: { id: string }[] }): Promise<void> => {
    if (text?.length || medias?.length || handbooks?.length) {
      const newComment = await postCommentFn({
        creatorSlug,
        handbooks,
        medias,
        messageId: commentId,
        messageType: isWorkflowComment ? MessageType.WorkflowComment : MessageType.Comment,
        productSlug,
        sendAsCreator,
        text: text
          ? {
            children: text,
          }
          : null,
      });

      if (newComment?.data) {
        onPostNewMessage?.(newComment.data);
      }
      mixpanelTrack(creatorSlug === person?.creator?.slug ? '(Creator) Added reply' : 'Added reply', {
        medias,
        parentId: commentId,
        text,
      });
    }
  };

  const handleAsyncSend = async ({ text, handbooks = [] }: { text?: { children: Descendant[] }[]; handbooks?: { id: string }[] }): Promise<void> => {
    const sendFunction = async (overrideMediaIds?: { id: string }[]): Promise<void> => {
      await handleSend({
        handbooks,
        medias: overrideMediaIds,
        text,
      });
    };
    addJob({
      dependsOn: uploadJobs,
      function: sendFunction,
      metadata: {
        replyingTo: commentId,
      },
      title: 'Comment Reply',
      type: JobType.COMMENT_REPLY,
    });
    setJobCorrelationId(nanoid());
    setIsThreadOpen(false);
  };

  return (
    <div className={s.comment}>
      {jobs.find(j => j.metadata?.replyingTo === commentId && j.status === JobStatus.IN_PROGRESS) && <ProgressIndicator isCentered />}
      {!!comments?.length && (comments.filter((c) => !c.isDeleted).length || 0) > 0 && (
        <>
          <div className={isThreadDetail ? s.comment__content__borderless : s.comment__content}>{children}</div>
        </>
      )}
      {canReply && !isReadOnly && !replyInline && !isThreadDetail && !isThreadOpen && (
        <WrapperComponent>
          <div className={s.replyButton}>
            <span className={s['comment__start-btn-icon']}>
              <ArrowElbowDownRight size={16} weight="fill" color="var(--color-gray-0)" />
            </span>
            Reply in thread
          </div>
        </WrapperComponent>
      )}
      {isThreadOpen && canReply && (
        creatorSlug === person?.creator?.slug
          ? (
            <InputComposer
              showAddVideo
              isLoomEnabled
              onSend={handleAsyncSend}
              showProTips={false}
              isFetching={false}
              placeholder='Type your reply'
              showAddHandbook
              isAsync
              jobCorrelationId={jobCorrelationId}
              referenceId={commentId}
              referenceType={'thread-comment'}
            />
          )
          : (
            <InputComposer
              showAddVideo
              onSend={handleSend}
              showProTips={false}
              isFetching={fetchingState.isFetching}
              placeholder='Type your reply'
              showAddHandbook={person?.creator?.slug === creatorSlug}
              referenceId={commentId}
              referenceType={'thread-comment'}
              isLoomEnabled={person?.creator?.slug === creatorSlug}
            />
          )
      )}
    </div>
  );
};

export default ThreadComment;
