import React, { Fragment, useContext, useEffect, useState } from 'react';
import { m, AnimatePresence } from 'framer-motion';

import ProgressIndicator from '@components/ProgressIndicator';
import { deleteMessage, getAllParticipantsOfBroadcast, postNewMessage, updateMessage } from '@tsClient';
import { NoodleProductTypes } from '@typings/graphql-models';
import { Message, MessageType } from '@typings/api-models';
import useNoodleApi from '@hooks/useNoodleApi';
import Noodle from '@components/Icons/Noodle';
import PushPin from '@components/Icons/PushPin';
import CurrencyDollarSimple from '@components/Icons/CurrencyDollarSimple';
import Trash from '@components/Icons/Trash';
import PencilSimple from '@components/Icons/PencilSimple';
import PushPinSlash from '@components/Icons/PushPinSlash';
import Broadcast from '@components/Icons/Broadcast';
import Cookie from '@components/Icons/Cookie';
import NoodleAI from '@components/Icons/NoodleAI';
import { useUser } from '@hooks';

import InputComposer from '@components/InputComposer';
import Shimmer from 'components/Shimmer';
import { mixpanelTrack } from '@providers/Mixpanel';
import { Descendant } from 'slate';
import BroadcastLock from '@components/BroadcastLock';
import Upsell from '@lib/UserProfile/components/Upsell';
import UserImage from '@components/UserImage';
import CustomLink from '@components/CustomLink';
import * as format from '@format';
import { format as formatDate, isSameDay } from 'date-fns';
import TeamsContext from '@providers/Teams/TeamsContext';

import Spacer from '@components/Spacer';
import SecondaryActions from '@/components/DesignLibrary/SecondaryActions';
import removeNullish from '@/helpers/removeNullish';
import CommentContent from '../CommentContent';
import CommentParticipants from '../CommentParticipants';
import ThreadComment from './ThreadComment';
import s from './CommentMessage.module.scss';

type Comment = Pick<Message, 'id' | 'createdAt' | 'isDeleted' | 'text' | 'title'> & {
  type?: Message['type'] | null; // @todo - shouldn't be optional, but is in a bunch of places
  canReply?: boolean;
  isPinned?: boolean;
  areCommentsHidden?: boolean;
  isContentHidden?: boolean;
  canReplyForFree?: boolean;
  isAvailableForFree?: boolean;
  upgradeTiers?: string[];
  participants?:
    | {
        id: string;
        color: string | null;
        logo: string | null;
        name: string | null;
      }[]
    | null;
  owner?: {
    id: string;
    name?: string | null;
    primaryColour?: {
      hex: string;
    } | null;
    image?: {
      url: string;
    } | null;
  } | null;
  richText?: {
    html: string;
  } | null;
  parent?: {
    type?: MessageType | null;
    id: string;
    parent?: {
      id: string;
    } | null;
  } | null;
  product?: {
    // sub comments don't have a product...
    id: string;
    slug: string;
    productTypes?: {
      noodleProductType?: NoodleProductTypes | null;
    }[];
  } | null;
  children?: {
    id: string;
    createdAt: string;
    upgradeTiers: string[];
    isDeleted: boolean;
    text: string | null;
    title: string | null;
    owner?: {
      id: string;
      name?: string | null;
      primaryColour?: {
        hex: string;
      } | null;
      image?: {
        url: string;
      } | null;
    } | null;
    children?: {
      id: string;
      createdAt: string;
      upgradeTiers: string[];
      isDeleted: boolean;
      text: string | null;
      title: string | null;
      richText?: {
        html: string;
      } | null;
      owner?: {
        id: string;
        name?: string | null;
        image?: {
          url: string;
        } | null;
      } | null;
    }[];
  }[];
  attachments?: Parameters<typeof CommentContent>[0]['comment']['attachments'];
  medias?: Parameters<typeof CommentContent>[0]['comment']['medias'];
  products?: Parameters<typeof CommentContent>[0]['comment']['products'];
};

type PropsType = {
  refetchMessage?: () => Promise<void>;
  isThreadDetail?: boolean;
  isThreadComment?: boolean;
  isChatMessage?: {
    isAutomated?: boolean;
    isAutomatedFromNoodle?: boolean;
    isPaid?: boolean;
    isBroadcastMessage?: boolean;
    isByteMessage?: boolean;
    isResponding?: boolean;
  };
  creatorPersonId: string;
  creatorSlug: string;
  product?: NonNullable<Parameters<typeof BroadcastLock>[0]['product']>;
  productSlug?: string | null;
  onPostNewMessage?: (comment: Awaited<ReturnType<typeof postNewMessage>>) => void;
  onDeleteMessage?: (messageId: string, subMessageId: string | undefined) => void;
  comment: Comment;
  commentId?: string;
  isReadOnly?: boolean;
  canPinMessages: boolean;
  canDeleteMessages: boolean;
  canReplyToMessages: boolean;
  replyInline?: boolean;
  person?: {
    id: string;
    creator?: {
      slug: string;
    } | null;
  };
  onEditClick?: () => void;
  containerRef?: React.RefObject<HTMLDivElement>;
  customerId?: string;
  reloadConversation?: () => Promise<void>;
  isWorkflowComment?: boolean;
};

type AvatarProps = Pick<PropsType, 'isChatMessage' | 'comment'> & {
  user: { id: string } | null;
};

const Avatar: React.FC<AvatarProps> = ({ comment, isChatMessage, user }) => {
  const { isAutomated, isAutomatedFromNoodle, isBroadcastMessage, isByteMessage, isPaid } = isChatMessage || {};
  return (
    <>
      {isAutomatedFromNoodle
        ? (
          <div className={s['comment-msg__noodle']}>
            <Noodle />
          </div>
        )
        : (
          <UserImage
            url={comment.owner?.image?.url}
            color={(comment.owner?.primaryColour?.hex) || undefined}
            name={
              format.messageOwnerName({ owner: comment.owner, user })
              || undefined
            }
            size={40}
          />
        )}
      {comment.isPinned && (
        <div className={s['comment-msg__pin']}>
          <PushPin color="white" weight="fill" size={10} />
        </div>
      )}
      {(isBroadcastMessage || isByteMessage) && (
        <div className={s['comment-msg__product']}>
          {isBroadcastMessage ? <Broadcast color="var(--color-gray-0)" weight="fill" /> : <Cookie color="var(--color-gray-0)" weight="fill" />}
        </div>
      )}
      {isAutomated && (
        <div className={s['comment-msg__automated']}>
          <NoodleAI size={10} />
        </div>
      )}
      {isPaid && !isAutomated && (
        <div className={s['comment-msg__paid']}>
          <CurrencyDollarSimple color="white" weight="fill" size={10} />
        </div>
      )}
    </>
  );
};

const CREATORS_WITHOUT_UPSELL = ['peterdanyliv'];

const CommentMessage: React.FC<PropsType> = ({
  comment,
  onEditClick,
  isThreadDetail,
  onPostNewMessage,
  creatorPersonId,
  creatorSlug,
  product,
  productSlug,
  commentId,
  onDeleteMessage,
  isThreadComment,
  isReadOnly = false,
  replyInline = false,
  person,
  refetchMessage,
  isChatMessage,
  canPinMessages,
  canDeleteMessages,
  canReplyToMessages,
  containerRef,
  customerId,
  reloadConversation,
  isWorkflowComment,
}) => {
  const [user] = useUser();
  const { currentTeamOwner, teamUserId } = useContext(TeamsContext);
  const { fetchingState, getData: postCommentFn } = useNoodleApi(postNewMessage, {
    healthMonitor: { name: 'send-message' },
  });
  const { getData: deleteCommentFn } = useNoodleApi(deleteMessage);
  const { getData: updateMessageFn } = useNoodleApi(updateMessage);
  const [isUpdatingPinState, setIsUpdatingPinState] = useState(false);

  const isToday = isSameDay(new Date(), new Date(comment.createdAt));
  const commentType = comment?.type;
  const isCreator = !!currentTeamOwner
    && teamUserId === currentTeamOwner?.person?.id
    && creatorSlug === currentTeamOwner.slug;

  const handleSend = async ({
    text,
    medias = [],
    handbooks = [],
  }: {
    text?: { children: Descendant[] }[];
    medias?: { id: string }[];
    handbooks?: { id: string }[];
  }): Promise<void> => {
    if ((text?.length || medias?.length || handbooks?.length) && productSlug) {
      const newComment = await postCommentFn({
        creatorSlug,
        handbooks,
        medias,
        messageId: commentId,
        messageType: isWorkflowComment ? MessageType.WorkflowComment : MessageType.Comment,
        productSlug,
        sendAsCreator: isCreator,
        text: text ? { children: text } : null,
      });
      if (newComment?.data) {
        onPostNewMessage?.(newComment.data);
        mixpanelTrack('Added reply', {
          medias,
          parent: commentId,
          text,
        });
      } else {
        mixpanelTrack('(Creator) Added reply', {
          medias,
          parent: commentId,
          text,
        });
      }
    }
  };

  const {
    data: participantsData,
    fetchingState: fetchingParticipantsState,
    getData: getAllParticipantsOfBroadcastFn,
  } = useNoodleApi(getAllParticipantsOfBroadcast);

  useEffect(() => {
    if (commentId && creatorSlug && productSlug) {
      getAllParticipantsOfBroadcastFn({
        creatorSlug,
        messageId: commentId,
        productSlug,
      });
    }
  }, [commentId, creatorSlug, getAllParticipantsOfBroadcastFn, productSlug]);

  const handleDelete = async (): Promise<void> => {
    if (comment.id) {
      await deleteCommentFn({ creatorSlug, messageId: comment.id, productSlug: productSlug ?? 'no-product-slug' });
      onDeleteMessage?.(comment.id, undefined);
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      const elementId = window.location.hash.substring(1);
      if (elementId === comment.id) {
        const element = document.getElementById(elementId);
        element?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [comment]);

  const updatePinState = async (): Promise<void> => {
    setIsUpdatingPinState(true);
    if (productSlug) {
      await updateMessageFn({ ...comment, creatorSlug, isPinned: !comment.isPinned, messageId: comment.id, productSlug });
      await refetchMessage?.();
    }
    setIsUpdatingPinState(false);
  };

  const handleDeleteSubComment = (commentIdProp: string) =>
    (subCommentId: string): void => {
      onDeleteMessage?.(commentIdProp, subCommentId);
    };

  const childComments = [...(comment?.children || [])];

  const isPost = (type?: MessageType | null): boolean =>
    type === MessageType.PaidBroadcast || type === MessageType.FreeBroadcast || type === MessageType.CommunityPost;

  const canReply = canReplyToMessages ? !comment.parent?.parent : false;

  const uniqueParticipants = participantsData?.participants?.filter(
    (participant, index, self) => self.findIndex(p => p.id === participant.id) === index,
  );

  const msgOptions = [
    canDeleteMessages
      ? {
        destructive: true,
        icon: Trash,
        value: 'Delete',
      }
      : null,
  ].filter(removeNullish);

  return (
    <>
      <m.div
        id={comment.id}
        className={s['comment-msg']}
        whileInView={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ delay: 0.2, duration: 1, type: 'spring' }}
        viewport={{ once: false, root: containerRef }}
      >
        {comment.isContentHidden && !isThreadComment && (
          <BroadcastLock
            upgradeTiers={comment.upgradeTiers || []}
            product={product}
            creatorSlug={creatorSlug}
            hasFreeAccess={comment.isAvailableForFree}
            commentId={comment.id}
            isCommunityPost={comment.owner?.id !== creatorPersonId}
          />
        )}
        {comment.isDeleted && comment.type !== MessageType.ProductDiscussion && (comment.children?.filter(c => !c.isDeleted).length || 0) > 0 && (
          <div className={`body-sm ${s['comment-msg__deleted']}`}>
            <Trash weight="fill" size={20} />
            This message has been deleted
          </div>
        )}
        {!comment.isDeleted && (
          <Fragment>
            <div className={s['comment-msg__user']}>
              {(!comment.isContentHidden || isThreadComment) && (
                <div className={s['comment-msg__user-logo']}>
                  <div className={s['comment-msg__user-logo']}>
                    {comment?.owner?.id === creatorPersonId
                      ? (
                        <CustomLink to={`/${creatorSlug}`} className={s['comment-msg__user-logo-container']}>
                          <Avatar
                            comment={comment}
                            user={user}
                            {...(isChatMessage && { isChatMessage })}
                          />
                        </CustomLink>
                      )
                      : (
                        <Avatar
                          comment={comment}
                          user={user}
                          {...(isChatMessage && { isChatMessage })}
                        />
                      )}
                  </div>
                  <div>
                    {comment?.owner?.id === creatorPersonId
                      ? (
                        <CustomLink to={`/${creatorSlug}`}>
                          <strong className={`action ${!isChatMessage && s['comment-msg__creator-name']}`}>
                            {format.messageOwnerName({ owner: comment?.owner, user })}
                          </strong>
                        </CustomLink>
                      )
                      : (
                        <>
                          <strong className="body-md-bold">{format.messageOwnerName({ owner: comment?.owner, user })}</strong>
                        </>
                      )}
                    <p className="caption" style={{ color: 'var(--color-gray-75)', whiteSpace: 'pre' }}>
                      {isChatMessage?.isAutomated && 'Assisted by Noodle AI · '}
                      {isToday
                        ? `Today, ${formatDate(new Date(comment.createdAt), 'hh:mm a')}`
                        : formatDate(new Date(comment.createdAt), 'MMMM do, hh:mm a')}
                    </p>
                  </div>
                </div>
              )}
              <AnimatePresence>
                <div className={s['comment-msg__user-edit']}>
                  {!replyInline && canPinMessages && (
                    <m.button layout className={s['comment-msg__pin-button']} onClick={updatePinState}>
                      {isUpdatingPinState
                        && (
                          <ProgressIndicator />
                        )
                        || (comment.isPinned
                          ? (
                            <PushPinSlash weight="fill" color="var(--color-gray-75)" size={20} />
                          )
                          : (
                            <PushPin weight="fill" color="var(--color-gray-75)" size={20} />
                          ))}
                    </m.button>
                  )}
                  {!replyInline && (isCreator || comment.owner?.id === user?.id) && isPost(commentType) && (
                    <m.button layout className={s['comment-msg__edit']} onClick={onEditClick}>
                      <PencilSimple weight="fill" color="var(--color-gray-75)" size={20} />
                      <span className="body-md-bold" style={{ color: 'var(--color-gray-75)' }}>
                        Edit
                      </span>
                    </m.button>
                  )}
                  {!replyInline && !comment.isDeleted && (canDeleteMessages || canPinMessages) &&(
                    <SecondaryActions
                      ariaLabel={'Delete'}
                      hideTooltip
                      options={msgOptions}
                      onSelect={value =>
                      {
                        if (value === 'Delete') {
                          handleDelete();
                        }
                      }}
                      position={'bottom-right'}
                    />
                  )}
                </div>
              </AnimatePresence>
            </div>
            <m.div
              className={s['comment-msg__msg']}
              animate={isChatMessage?.isResponding ? { opacity: [0.2, 0.7] } : {}}
              transition={{ duration: 0.5, repeat: Infinity, repeatType: 'mirror' }}
            >
              <CommentContent
                comment={comment}
                customerId={customerId}
                creator={{ personId: creatorPersonId, slug: creatorSlug }}
                reloadConversation={reloadConversation}
              />
              {/* TODO - move retrieve participants to <commentParticipants> after migration to postgres */}
              {(uniqueParticipants?.length || 0) > 0 && (
                <CommentParticipants participants={uniqueParticipants || []} userLogoSize={24} />
              )}
              {fetchingParticipantsState.isFetching && <ProgressIndicator />}
            </m.div>
          </Fragment>
        )}

        {isThreadDetail && comment.canReply && isPost(commentType) && !CREATORS_WITHOUT_UPSELL.includes(creatorSlug) && (
          <div className={s['comment-msg__upsells']}>
            <Upsell creatorSlug={creatorSlug} type={NoodleProductTypes.Lite} isMinified />
          </div>
        )}

        {isThreadDetail && canReply && (!comment.isContentHidden || comment.canReply) && (
          <div
            style={{
              height: !fetchingState.isFetching && !comment.canReply ? 480 : undefined,
              margin: '24px 0',
              position: 'relative',
            }}
          >
            <InputComposer
              showAddVideo
              onSend={handleSend}
              showProTips={false}
              isFetching={fetchingState.isFetching}
              placeholder={commentId ? 'Type your reply' : 'Type your message'}
              isDisabled={!comment.canReply}
              showAddHandbook={isCreator}
              isLoomEnabled={isCreator}
              referenceType={'comment-message'}
              referenceId={comment.id}
            />
            {!fetchingState.isFetching && !comment.canReply && (
              <>
                <Spacer />
                <BroadcastLock
                  product={product}
                  creatorSlug={creatorSlug}
                  hasFreeAccess={comment.isAvailableForFree && comment.canReplyForFree}
                  upgradeTiers={comment.upgradeTiers || []}
                  isCommunityPost={comment.owner?.id !== creatorPersonId}
                />
              </>
            )}
          </div>
        )}
        {(!comment.isDeleted || (comment.children?.filter(c => !c.isDeleted).length || 0) > 0) && productSlug && (
          <ThreadComment
            canReply={canReply}
            isThreadDetail={isThreadDetail}
            comments={!isThreadDetail ? childComments.slice(0, 2) : childComments}
            commentId={comment.id}
            isReadOnly={isReadOnly}
            creatorSlug={creatorSlug}
            productSlug={productSlug}
            replyInline={replyInline}
            onPostNewMessage={onPostNewMessage}
            person={person}
            sendAsCreator={isCreator}
            isWorkflowComment={isWorkflowComment}
          >
            {[...(!isThreadDetail ? childComments.slice(0, 2) : childComments)].reverse().map(subComment => (
              <CommentMessage
                isThreadComment
                key={subComment.id}
                comment={subComment}
                creatorPersonId={creatorPersonId}
                customerId={customerId}
                onDeleteMessage={() => handleDeleteSubComment(comment.id)(subComment.id)}
                creatorSlug={creatorSlug}
                product={product}
                productSlug={productSlug}
                refetchMessage={refetchMessage}
                canDeleteMessages={canDeleteMessages}
                canPinMessages={canPinMessages}
                canReplyToMessages={isCreator && canReply}
                isWorkflowComment={isWorkflowComment}
              />
            ))}
            {fetchingState.isFetching && (
              <div className={s['comment-msg__reply-shimmer']}>
                <Shimmer message="Posting Reply" rounded={true} />
              </div>
            )}
          </ThreadComment>
        )}
      </m.div>
    </>
  );
};

export default CommentMessage;
