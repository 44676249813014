import { FormEvent, ReactElement, useEffect, useState } from 'react';

import InputField from '@components/FormFields/InputField';
import Button from 'components/Buttons';
import { getValidationErrors, Rules, ValidationErrors } from 'helpers/validation';
import * as Types from '@typings/graphql-models';
import getTypedKeys from '@helpers/getTypedKeys';
import useNoodleApi from '@hooks/useNoodleApi';
import * as ApiModels from '@typings/api-models';
import * as tsClient from '@tsClient';

type Props = {
  productPrice?: number;
  emailContent: Record<string, unknown>;
  listId: string;
  templateEmailId?: string | null;
  productType?: Pick<Types.ProductType, 'noodleProductType'> | null;
};

type Values = {
  email: string;
};

type FormErrors = ValidationErrors<Values>;

const JoinWaitList = ({ productPrice, emailContent, listId, templateEmailId, productType }: Props): ReactElement => {
  const [waitListRecordsCount, setWaitListRecordsCount] = useState(0);
  const [isJoined, setIsJoined] = useState(false);

  const RULES: Rules<Values> = {
    email: {
      isEmail: true,
      isRequired: true,
    },
  };

  const [values, setValues] = useState<Values>({
    email: '',
  });

  const [formErrors, setFormErrors] = useState(getValidationErrors(values, RULES));

  const { getData: addNewContactToListFn } = useNoodleApi(tsClient.addNewContactToList);
  const { getData: getCountOnEmailListFn } = useNoodleApi(tsClient.getCountOnEmailList);

  const handleInputChange = (key: string, newValue: string, options: { formErrors?: FormErrors }): void => {
    const { formErrors: inputFormErrors } = options;
    setValues(vals => ({ ...vals, [key]: newValue }));
    if (inputFormErrors) {
      setFormErrors(inputFormErrors);
    }
  };

  const isFormValid = (): boolean => !getTypedKeys(formErrors).some(key => !!formErrors[key]);

  const handleAddToWaitList = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    if (isFormValid()) {
      await addNewContactToListFn({
        email: values.email,
        emailContent,
        listId,
        templateId: templateEmailId || '',
      });
      setIsJoined(true);
      setValues(vals => ({ ...vals, email: '' }));
    }
  };

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { data: res } = await getCountOnEmailListFn({ listId });
      setWaitListRecordsCount(res?.count || 0);
    };

    fetchData();
  }, [listId, getCountOnEmailListFn]);

  return (
    <form onSubmit={handleAddToWaitList} style={{ marginBottom: 32 }}>
      <label
        htmlFor="email"
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 16,
        }}
      >
        {productPrice && (
          <span style={{ fontSize: '28px' }}>
            <b>${productPrice}</b>
          </span>
        )}
        {productType?.noodleProductType !== ApiModels.NoodleProductTypes.Lite && (
          <span
            style={{
              MozOsxFontSmoothing: 'grayscale',
              WebkitFontSmoothing: 'antialiased',
            }}
          >
            Join <b>{waitListRecordsCount} others</b> on the waitlist.
          </span>
        )}
      </label>
      <div style={{ display: 'grid', gridGap: 16, gridTemplateColumns: '2fr 1fr' }}>
        <InputField
          id={'145'}
          key='email'
          name='email'
          rules={RULES}
          values={values}
          hasFixedHeight={false}
          formErrors={formErrors}
          placeholder='Your email'
          onChange={handleInputChange.bind(this, 'email')}
        />
        <Button isSecondary={!isJoined} disabled={isJoined}>
          {!isJoined ? 'Join Waitlist' : 'Joined!'}
        </Button>
      </div>
    </form>
  );
};

export default JoinWaitList;
