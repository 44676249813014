import React, { useEffect, useState, useCallback } from 'react';
import ReactGA from 'react-ga';
import clsx from 'classnames';
import { useRouter } from 'next/router';
import ShoppingCart, { CartItem } from '@providers/ShoppingCart';

import Button from '@components/Buttons';
import CheckBox from '@components/FormFields/CheckBox';
import JoinWaitList from '@components/JoinWaitList';
import PurchaseButton from '@components/PurchaseButton';
import { logError } from '@providers/ErrorTracking';
import { getId, isLiteProduct, isInIframe, isSaaSProduct } from '@helpers/helper';
import * as Types from '@typings/graphql-models';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import { gaEvent, getUTMParams } from 'helpers/analytics';
import { getEcommerceItemFromProduct } from 'helpers/ecommerce';
import useUser from '@providers/Auth/useUser';
import { getSubscriptionFromProductId, isProductBuy, isTierBuy } from '@tsClient';
import * as format from '@format';
import { SELF_ORIGIN } from '@configuration/client';
import scrollParentToTop from 'helpers/scrollParentToTop';

import LocalCurrencyConversion from '@components/LocalCurrencyConversion';
import { DEFAULT_CURRENCY } from '@typings/common';
import s from './BuyProductButton.module.scss';

const { Interval, NoodleProductTypes, StripePriceUsageType } = Types;

type UpsellSubscriptionModel = Pick<Types.SubscriptionModel, 'id' | 'handbookAlias'> & {
  product?:
    | (Pick<Types.Product, 'id' | 'state'> & {
        prices: Array<Omit<CartItem, 'product'> & { product?: CartItem['product'] | null }>;
      })
    | null;
};

type UpsellProductModel =
  | (Pick<Types.Product, 'id' | 'state'> & {
      prices: Array<
        Pick<Types.Price, 'id' | 'price' | 'recurringInterval' | 'tierId' | 'currency' | 'usageType' | 'isActive'> & {
          product?: CartItem['product'] | null;
        }
      >;
    })
  | null;

type TheseUpgradeTiers = string[];

type Props = {
  product: Pick<Types.Product, 'id' | 'includesBroadcasts' | 'slug' | 'state' | 'title' | 'isTierBasedPermissioningEnabled'> & {
    prices: Array<CartItem & Pick<Types.Price, 'price' | 'isActive'>>;
    productTypes: Array<Pick<Types.ProductType, 'noodleProductType' | 'sendGridContactListId' | 'contactListTemplateEmailId'>>;
    creator?: Pick<Types.Creator, 'name' | 'slug'> & {
      person?: { id: string } | null;
      isBuyEnabled: boolean;
    } | null;
    handbookSteps: Array<unknown>;
    productCommentPermissions: Array<{ tierId: string }>;
    isBuyEnabled: boolean;
  };
  redirect: boolean;
  isFreeMembership: boolean;
  noSwitch: boolean;
  upgradeTiers: string[];
  getPriceFromCommentPermissions?: boolean;
};

const priceHasProduct = (
  price: (Pick<Types.Price, 'id' | 'price' | 'recurringInterval'> & { product?: CartItem['product'] | null }) | null | undefined,
): price is CartItem => Boolean(price?.product);

const pickUpsellPrice = (upgradeTiers: TheseUpgradeTiers, product: UpsellProductModel | null): CartItem | null => {
  const activePrices = product?.prices?.filter(p => p.isActive);
  if (!activePrices) {
    return null;
  }

  if (upgradeTiers.length === 0) {
    const nonZeroMonthly = activePrices.find(p => p.price !== 0 && p.recurringInterval === Interval.Month);
    if (priceHasProduct(nonZeroMonthly)) {
      return nonZeroMonthly;
    }

    const firstPrice = activePrices[0];
    if (priceHasProduct(firstPrice)) {
      return firstPrice;
    }
  }

  const pricesWithPermission = activePrices.filter(price => price.tierId && upgradeTiers?.includes(price.tierId));

  const lowestPriceWithPermission = pricesWithPermission?.sort((a, b) => {
    if (a.price < b.price) return -1;
    return 0;
  })[0];

  if (priceHasProduct(lowestPriceWithPermission)) {
    return lowestPriceWithPermission;
  }

  return null;
};

const BuyProductButton: React.FC<Props> = ({
  getPriceFromCommentPermissions = false,
  upgradeTiers,
  product,
  redirect,
  isFreeMembership,
  noSwitch,
}) => {
  const router = useRouter();
  const isLite = isLiteProduct(product);
  const { creator } = product;
  const productState = product.state;
  const productTypeContactListId = product?.productTypes[0]?.sendGridContactListId;
  const isResponseProduct = product.productTypes.some(t => t.noodleProductType === NoodleProductTypes.Response);
  const isBroadcastProduct = product.includesBroadcasts;

  const [subscription, setSubscription] = useState<UpsellSubscriptionModel | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingSubscription, setIsFetchingSubscription] = useState(false);
  const [activePrice, setActivePrice] = useState<CartItem | null>(null);
  const [alreadyBuyIt, setAlreadyBuyIt] = useState(false);
  const [savedPercentage, setSavedPercentage] = useState(0);
  const [user] = useUser();

  const upsellPrice = pickUpsellPrice(upgradeTiers, product);

  const activePrices = product?.prices?.filter(p => p.isActive);

  const anyFreePrices = Boolean(activePrices.find(p => p.price === 0));
  const activePriceIsFree = activePrice?.price === 0;
  const showBuySubscriptionButton = !isResponseProduct && !isLite
    && (subscription || getPriceFromCommentPermissions) && !alreadyBuyIt && !isBroadcastProduct && product.isBuyEnabled;
  const subscriptionPurchasePrice = getPriceFromCommentPermissions
    ? subscription?.product?.prices
      .find(price => product.productCommentPermissions.find((p) => p.tierId === price.tierId))
    : subscription?.product?.prices.find(price => price.recurringInterval === Interval.Month);
  const showBuyProductButton = !showBuySubscriptionButton;
  const showJoinForFreeButton = isFreeMembership && anyFreePrices && !activePriceIsFree;
  const showActivePriceToggle = !!savedPercentage && !alreadyBuyIt && !noSwitch;
  const isSaasProduct = isSaaSProduct(product);

  const BuyFreeProduct = async (): Promise<void> => {
    if (!creator) {
      logError(new Error('Trying to purchase a product without a creator'), { productId: product.id });
      return;
    }
    setIsFetching(true);
    gaEvent('click_buy_free_product_button', {
      event_category: 'ecommerce',
      event_label: 'Buy free product clicked',
    });
    ReactGA.plugin.execute('ec', 'addProduct', getEcommerceItemFromProduct(product));
    ReactGA.plugin.execute('ec', 'setAction', 'add');
    if (!isInIframe()) {
      await ShoppingCart.addProduct({ price: activePrices.find(price => price.price === 0) || activePrices[0] });
      await router.push(
        getUrl(IDENTIFIERS.CART, {
          creatorSlug: creator.slug,
          isFreeMembership,
          ...getUTMParams(router.query),
        }),
      );
    } else {
      setIsFetching(false);
      window.open(
        `${SELF_ORIGIN}${getUrl(IDENTIFIERS.PRODUCT_PURCHASE, {
          creatorSlug: creator.slug,
          isFreeMembership,
          priceId: activePrices[0].id,
          productSlug: product.slug,
        })}`,
      );
    }
  };

  const BuyProduct = async (): Promise<void> => {
    if (!creator) {
      logError(new Error('Trying to purchase a product without a creator'), { productId: product.id });
      return;
    }
    setIsFetching(true);
    ReactGA.plugin.execute('ec', 'addProduct', getEcommerceItemFromProduct(product));
    ReactGA.plugin.execute('ec', 'setAction', 'add');
    if (!isInIframe()) {
      await ShoppingCart.addProduct({ price: activePrice || activePrices[0] });
      await router.push(
        getUrl(IDENTIFIERS.CART, {
          creatorSlug: creator.slug,
          ...getUTMParams(router.query),
        }),
      );
    } else {
      setIsFetching(false);
      window.open(
        `${SELF_ORIGIN}${getUrl(IDENTIFIERS.PRODUCT_PURCHASE, {
          creatorSlug: creator.slug,
          priceId: (activePrice || activePrices[0]).id,
          productSlug: product.slug,
        })}`,
      );
    }
  };

  const BuySubscription = async (): Promise<void> => {
    if (!creator) {
      logError(new Error('Trying to purchase a product without a creator'), { productId: product.id });
      return;
    }

    if (!subscriptionPurchasePrice) {
      logError(new Error('Attempting to buy subscription but none present'), { productId: product.id, subscriptionId: subscription?.id });
      return;
    }

    setIsFetching(true);
    ReactGA.plugin.execute('ec', 'addProduct', getEcommerceItemFromProduct(product));
    ReactGA.plugin.execute('ec', 'setAction', 'add');
    if (!isInIframe() && priceHasProduct(subscriptionPurchasePrice)) {
      await ShoppingCart.addProduct({ price: subscriptionPurchasePrice });
      await router.push(
        getUrl(IDENTIFIERS.CART, {
          creatorSlug: creator.slug,
          isSaasProduct,
          ...getUTMParams(router.query),
        }),
      );
    } else {
      setIsFetching(false);
      window.open(
        `${SELF_ORIGIN}${getUrl(IDENTIFIERS.PRODUCT_PURCHASE, {
          creatorSlug: creator.slug,
          isSaasProduct,
          priceId: subscriptionPurchasePrice.id,
          productSlug: product.slug,
        })}`,
      );
    }
  };

  useEffect(() => {
    if (upsellPrice) {
      const monthPrice = upsellPrice;
      const yearPrice = activePrices.find(price => price.tierId === upsellPrice.tierId && price.recurringInterval === Interval.Year)
        || activePrices.find(price => price.recurringInterval === Interval.Year);
      if (yearPrice && monthPrice) {
        setSavedPercentage(100 - Math.ceil((+yearPrice.price * 100) / (+monthPrice.price * 12)));
      }
      if (product?.creator?.slug === 'danny-maude') {
        setActivePrice(yearPrice || monthPrice || null);
      } else {
        setActivePrice(monthPrice || yearPrice || null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, upsellPrice]);

  useEffect(() => {
    setIsFetchingSubscription(true);
    const fetchSubscriptionData = async (): Promise<void> => {
      try {
        const subscriptionsForProduct = await getSubscriptionFromProductId({ productId: product.id });
        const productSubscription = (subscriptionsForProduct?.productTypes || []).find(p => p.subscriptionModel && p.subscriptionModel.length);
        const productContainingSubscription = productSubscription?.subscriptionModel?.find(m => m.product);
        if (productContainingSubscription) {
          setSubscription(productContainingSubscription);
        }
      } catch (e) {
        logError(e);
      }
      setIsFetchingSubscription(false);
    };
    fetchSubscriptionData();
  }, [product.id]);

  const redirectToHandbook = useCallback(
    (res): void => {
      if (!creator) {
        logError(new Error('Trying to redirect to a handbook without a creator'), { productId: product.id });
        return;
      }
      setAlreadyBuyIt(res.status);
      if (res.status && product.handbookSteps.length && redirect) {
        router.push(
          getUrl(IDENTIFIERS.PRODUCT, {
            creatorSlug: creator.slug,
            productSlug: product.slug,
          }),
        );
      } else if (res.status && redirect) {
        router.push(getUrl(IDENTIFIERS.USER_PROFILE, { creatorSlug: creator.slug }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [creator, product, redirect],
  );

  const userId = user?.id;
  const creatorPersonId = creator?.person?.id;
  useEffect((): void => {
    const handlePurchase = async (): Promise<void> => {
      if (userId && userId !== creatorPersonId) {
        if (product.isTierBasedPermissioningEnabled && upsellPrice && upsellPrice?.tierId) {
          isTierBuy({ id: getId(upsellPrice.tierId) })
            .then(res => redirectToHandbook(res))
            .catch(logError);
        } else if (getPriceFromCommentPermissions && product.productCommentPermissions.length > 0) {
          for (let i = 0; i < product.productCommentPermissions.length; i += 1) {
            try {
              // eslint-disable-next-line no-await-in-loop
              const isBought = await isTierBuy({ id: product.productCommentPermissions[i].tierId });
              redirectToHandbook(isBought);
              if (isBought) {
                return;
              }
            } catch (e) {
              logError(e);
            }
          }
        } else {
          isProductBuy({ id: getId(product.id) })
            .then(res => redirectToHandbook(res))
            .catch(logError);
        }
      }
      scrollParentToTop();
    };
    handlePurchase();
  }, [
    getPriceFromCommentPermissions,
    userId,
    creatorPersonId,
    product.id,
    product.productCommentPermissions,
    product.isTierBasedPermissioningEnabled,
    redirect,
    redirectToHandbook,
    upsellPrice,
  ]);

  const getSaleButtonText = (): string => {
    if (isResponseProduct || isLite) {
      return `Chat with ${creator?.name || 'the creator'}`;
    }
    if (!activePrice) {
      return `Buy ${activePrices[0]?.price ? `for ${format.price.withCurrency(activePrices[0].price, activePrices[0].currency)}` : ''}`;
    }
    return activePrice.price === 0
      ? 'Get for free'
      : `${format.price.withCurrency(activePrice?.price, activePrice.currency)}${
        activePrice?.recurringInterval ? `${activePrice?.recurringInterval === Interval.Year ? '/year' : '/mo'}` : ''
      }`;
  };

  const getPriceText = (): string =>
    activePrice
      ? `${format.price.recurring(activePrice)}`
      : `${format.price.recurring(activePrices?.[0])}`;

  const handleBuyButtonText = (buttonText: string): string => (alreadyBuyIt ? 'You already have this product.' : buttonText);

  const getMenuItemText = (price: Pick<Types.Price, 'usageType' | 'recurringInterval' | 'price' | 'currency'>): string => {
    if (price?.usageType === StripePriceUsageType.Recurring) {
      return `Subscribe for ${format.price.recurring(price)}`;
    }
    if (isResponseProduct || isLite) {
      return `Chat with ${creator?.name || 'the creator'} for ${format.price.withCurrency(price.price, price.currency)}`;
    }
    return `Buy ${price.price ? `for ${format.price.withCurrency(price.price, price.currency)}` : ''}`;
  };

  const getMenuItems = (): Array<{ title: string; onClick: () => ReturnType<typeof BuyProduct> }> => {
    const items: Array<{ title: string; onClick: () => ReturnType<typeof BuyProduct> }> = [];
    activePrices.forEach(price => {
      items.push({
        onClick: () => BuyProduct(),
        title: getMenuItemText(price),
      });
    });

    return items;
  };

  if (productState !== 'on_sale' && productTypeContactListId) {
    return (
      <JoinWaitList
        productPrice={activePrices[0]?.price}
        listId={productTypeContactListId}
        productType={product.productTypes[0]}
        templateEmailId={product?.productTypes[0]?.contactListTemplateEmailId}
        emailContent={{}}
      />
    );
  }

  if (productState !== 'on_sale') {
    return (
      <Button disabled isLarge isSecondary isFullWidth>
        {'Coming Soon!'}
      </Button>
    );
  }

  const hasMenu = !!activePrices?.length && activePrices?.length > 2;

  const showPriceInLocalCurrency = (): boolean => Boolean(!isResponseProduct && !isLite && activePrice);

  const handleToggleYearMonth = (): void => {
    setActivePrice(
      activePrices?.find(
        price =>
          price.tierId === upsellPrice?.tierId
          && price.recurringInterval === (activePrice?.recurringInterval === Interval.Month ? Interval.Year : Interval.Month),
      )
        || activePrices?.find(({ recurringInterval }) =>
          activePrice?.recurringInterval === Interval.Year ? recurringInterval === Interval.Month : recurringInterval === Interval.Year,
        )
        || null,
    );
  };

  const firstActivePrice = activePrices.find(p => p.price !== 0) || activePrices[0];

  return (
    <div className={clsx(s['buy-button__wrapper'], isLite && s['buy-button__wrapper--fixed-on-mobile'])}>
      {showActivePriceToggle && (
        <CheckBox
          id="toggle-yearly-monthly-subscription"
          isSwitch
          label={<strong style={{ marginLeft: 8 }}>Save {savedPercentage}% annually</strong>}
          hasFixedHeight={false}
          disabled={false}
          onChange={handleToggleYearMonth}
          values={{
            annual: activePrice?.recurringInterval === Interval.Year,
          }}
          name="annual"
        />
      )}
      {showBuySubscriptionButton && (
        <PurchaseButton
          hasActivePrice={Boolean(activePrices[0]) && product.productCommentPermissions.length === 0}
          firstActivePrice={firstActivePrice}
          isFetching={isFetching}
          onClick={BuyProduct}
          hasSubscription={Boolean(subscription)}
          subscriptionPrice={subscriptionPurchasePrice || { currency: DEFAULT_CURRENCY, price: 0 }}
          isFetchingSubscription={isFetchingSubscription || isFetching}
          isBroadcast={isBroadcastProduct || false}
          labelSubscription={
            subscriptionPurchasePrice?.product?.creator?.name
              ? `Get exclusive access to ${subscriptionPurchasePrice?.product?.creator?.name}`
              : 'Get exclusive access'
          }
          onSubscriptionClick={BuySubscription}
          trialPeriodDays={subscriptionPurchasePrice?.trialPeriodDays || 0}
        />
      )}
      {showBuyProductButton && (
        <div className={s['buy-button__subscription-wrapper']}>
          <Button
            isSecondary
            isFullWidth
            onClick={() => BuyProduct()}
            disabled={alreadyBuyIt || !product.isBuyEnabled}
            isShimmering={isFetching}
            multiline={(isResponseProduct || isLite) && !alreadyBuyIt}
            {...(hasMenu ? { isWidthFixed: true, menuItems: getMenuItems() } : {})}
          >
            {isBroadcastProduct && <p className={s['buy-button__become-member-text']}>Become a member today</p>}
            <span className={clsx(s['buy-product-text'])}>{handleBuyButtonText(getSaleButtonText())}</span>
            {(isResponseProduct || isLite) && !alreadyBuyIt && <p className={clsx(s['buy-button__price-text'])}>{getPriceText()}</p>}
          </Button>
          {showPriceInLocalCurrency() && (
            <LocalCurrencyConversion
              progressConfig={{ size: 12 }}
              price={activePrice?.price || 0}
              currency={activePrice?.currency || DEFAULT_CURRENCY}
              interval={activePrice?.recurringInterval}
            />
          )}
        </div>
      )}
      {showJoinForFreeButton && (
        <Button
          onClick={BuyFreeProduct}
          isFourth
          isFullWidth
          isShimmering={isFetching}
          disabled={alreadyBuyIt || !product.isBuyEnabled}
        >
          {handleBuyButtonText('Join for free!')}
        </Button>
      )}
    </div>
  );
};

export default BuyProductButton;
