import { ReactElement } from 'react';
import UserRehydratedGate from 'components/UserRehydratedGate';
import BuyProductButton from './BuyProductButton';

type ThisProduct = Parameters<typeof BuyProductButton>[0]['product'];

const BuyProductButtonWrapper: React.FC<{
  upgradeTiers: string[],
  product: ThisProduct,
  redirect: boolean,
  isFreeMembership?: boolean
  noSwitch?: boolean,
  getPriceFromCommentPermissions?: boolean;
}> = ({ getPriceFromCommentPermissions, product, redirect, isFreeMembership = false, noSwitch = false, upgradeTiers}):ReactElement => (
  <UserRehydratedGate>
    <BuyProductButton
      upgradeTiers={upgradeTiers}
      product={product}
      redirect={redirect}
      isFreeMembership={isFreeMembership}
      noSwitch={noSwitch}
      getPriceFromCommentPermissions={getPriceFromCommentPermissions}
    />
  </UserRehydratedGate>
);

export default BuyProductButtonWrapper;
